import axios from "axios";
import authHeader from "./auth-header";

//const API_URL = "http://localhost/api/auth/";
//const API_IB_URL = "http://localhost/api/ib/";
const API_URL = "https://ib.deepleey.com/api/auth/";
const API_IB_URL = "https://ib.deepleey.com/api/ib/";

export const authService_login = async (username, password) => {
  try {
    const response = await axios
      .post(API_URL + "signin", { username, password }, {withCredentials: false});
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));

      try {
        var config = {
          headers: authHeader(),
          mode: "cors",
          withCredentials: true
        }
        console.log("authService_login - config")
        console.log(config)
        const responseCatasto = await axios.get(API_IB_URL + "urlcatasto", config);
        console.log("authService_login - responseCatasto")
        console.log(responseCatasto)
        if(responseCatasto.data) {
          sessionStorage.setItem("urlCatasto", responseCatasto.data);
        }

        const responseGoogleApiKey = await axios.get(API_IB_URL + "googleapikey", config);
        console.log("authService_login - responseGoogleApiKey")
        console.log(responseGoogleApiKey)
        if(responseGoogleApiKey.data) {
          sessionStorage.setItem("googleApiKey", responseGoogleApiKey.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    return response.data;
  } catch (error) {
    throw (error);
  }
}

export const authService_logout = () => {
  localStorage.removeItem("user");
  window.location.href = "/login";
}

export const authService_register = (username, email, password) => {
      return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
}